<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <validation-provider v-slot="{ errors }" name="Aircraft" rules="required">
          <v-text-field
            v-model="aircraft_maintenances.aircraft_id"
            :error-messages="errors"
            label="Aircraft"
            required
            outlined
          >
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Description" rules="required">
          <v-text-field
            v-model="aircraft_maintenances.description"
            :error-messages="errors"
            label="Description"
            required
            outlined
          >
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Due At Hours" rules="required">
          <custom-time-picker
            v-model="aircraft_maintenances.due_at_hours"
            required
            label="Due At Hours"
            :error-messages="errors"
            @forceTimeUpdate="forceTimeUpdate"
          ></custom-time-picker>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Due Date" rules="required">
          <date-picker-dialog
            v-model="aircraft_maintenances.due_date"
            :minimum_date="`1950-01-01`"
            :error-messages="errors"
            :label="`Due Date`"
            @forceDateUpdate="forceDateUpdate"
          ></date-picker-dialog>
        </validation-provider>

        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Save
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";
import CustomTimePicker from "@/components/TimePickerDialog.vue";
import DatePickerDialog from "@/components/DatePickerDialog.vue";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "AircraftMaintenanceAdd",
  props: {
    aircraft_id: {
      type: String,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomTimePicker,
    DatePickerDialog,
  },
  data() {
    return { aircraft_maintenances: {} };
  },
  methods: {
    updateBrownse() {
      this.$emit("updateBrownse");
    },
    forceDateUpdate(value) {
      this.aircraft_maintenances.due_date = value;
    },
    forceTimeUpdate(value) {
      if (value) {
        this.aircraft_maintenances.due_at_hours = value;
      }
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        this.aircraft_maintenances.aircraft_id = this.aircraft_id;
        await Request.post(`${this.$IYA.URI.AIRCRAFT_MAINTENANCES__ADD}`, this.aircraft_maintenances).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.updateBrownse();
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
