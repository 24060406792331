var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer"},[_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Aircraft","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Aircraft","required":"","outlined":""},model:{value:(_vm.aircraft_maintenances.aircraft_id),callback:function ($$v) {_vm.$set(_vm.aircraft_maintenances, "aircraft_id", $$v)},expression:"aircraft_maintenances.aircraft_id"}})]}}])}),_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Description","required":"","outlined":""},model:{value:(_vm.aircraft_maintenances.description),callback:function ($$v) {_vm.$set(_vm.aircraft_maintenances, "description", $$v)},expression:"aircraft_maintenances.description"}})]}}])}),_c('validation-provider',{attrs:{"name":"Due At Hours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-time-picker',{attrs:{"required":"","label":"Due At Hours","error-messages":errors},on:{"forceTimeUpdate":_vm.forceTimeUpdate},model:{value:(_vm.aircraft_maintenances.due_at_hours),callback:function ($$v) {_vm.$set(_vm.aircraft_maintenances, "due_at_hours", $$v)},expression:"aircraft_maintenances.due_at_hours"}})]}}])}),_c('validation-provider',{attrs:{"name":"Due Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-dialog',{attrs:{"minimum_date":"1950-01-01","error-messages":errors,"label":"Due Date"},on:{"forceDateUpdate":_vm.forceDateUpdate},model:{value:(_vm.aircraft_maintenances.due_date),callback:function ($$v) {_vm.$set(_vm.aircraft_maintenances, "due_date", $$v)},expression:"aircraft_maintenances.due_date"}})]}}])}),_c('v-btn',{staticClass:"ma-10 pa-5",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Save ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }